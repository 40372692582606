// src/components/customFields.js
import React from 'react';
import { useRecordContext, TextField, Link, DateField} from 'react-admin';
import { Tooltip, List, ListItem } from '@mui/material';

const CustomOccurrencesField = ({ source }) => {
  const record = useRecordContext();
  if (!record || !record[source]) return null;

  return (
    <List dense style={{ margin: 0, padding: 0 }}>
      {record[source].map(occ => (
        <Tooltip key={occ.id} title={`From ${occ.start_date} to ${occ.end_date}`} placement="left" arrow>
          <ListItem >
            <DateField record={occ} source="start_date" showTime /> -
            <DateField record={occ} source="end_date" showTime sx={{paddingLeft: 2}}/>
          </ListItem>
        </Tooltip>
      ))}
    </List>
  );
};

const CustomTextField = (props) => {
  const record = useRecordContext();
  if (!record) return null;
  const text = record[props.source];

  const textStyle = {

    minWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.5em',
    height: '6em',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <TextField
      {...props}
      title={text}  // Tooltip on hover
      component="div"
      style={textStyle}
    />
  );
};

const CustomUrlField = ({ source, ...rest }) => {
  const record = useRecordContext();
  const url = record[source];
  if (!url) return null;

  return (
      <Link href={url} {...rest} target="_blank" style={{ display: 'inline-block', maxWidth: 200, overflow: 'hidden' }}>
          <span style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', display: 'inline-block', maxWidth: '100%' }}>
              {url}
          </span>
      </Link>
  );
};

export { CustomTextField, CustomUrlField, CustomOccurrencesField };