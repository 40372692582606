// src/resources/events/EventCreateEdit.js
import React, { useState } from 'react';
import { Create, Edit, SimpleForm, TextInput, DateTimeInput, ImageInput, ImageField, required, useRecordContext, Toolbar, SaveButton, ArrayInput, SimpleFormIterator, getValuesFromRecords } from 'react-admin';
import { Grid, Box, Typography } from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import LocationSelect from '../../components/locationSelectField';

const CustomToolbar = props => (
    <Toolbar {...props} sx={{
        background: 'none',
        padding: '16px',
    }}>
        <SaveButton 
            label="Save Event"
            icon={<EventAvailableIcon />}
            sx={{
                backgroundColor: 'primary.main',
                alignItems: 'center',
                '&:hover': {
                    backgroundColor: 'primary.dark',
                },
                color: 'white',
                padding: '12px 16px',
            }}
        />
    </Toolbar>
);

const EventForm = ({ mode, ...props }) => {
    const record = useRecordContext(props);

    const [selectedLocationId, setSelectedLocationId] = useState(record?.location_id || '');

    const defaultValue = {
        location_id: selectedLocationId,
    };
  

    const handleLocationChange = (value) => {
      setSelectedLocationId(value);
    };
  
    console.log('Current selectedLocationId:', selectedLocationId);

  
    return ( 
    <Box sx={{ margin: '0 auto', padding: '20px', maxWidth: '800px' }}>
        <SimpleForm {...props} toolbar={<CustomToolbar/>} defaultValues={defaultValue}>
            <Box mt={2}>
                <Typography variant="h6" gutterBottom sx={{paddingBottom: 2}}>
                    Event Details
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} >
                        <TextInput source="event_name" label="Event Name" multiline validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocationSelect onChange={handleLocationChange} value={selectedLocationId}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="description" label="Description" multiline validate={required()} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="external_link" label="External Link" multiline fullWidth />
                    </Grid>
                </Grid>
            </Box>

            <Box mt={2}>
            <Typography variant="h6" gutterBottom sx={{paddingBottom: 3}}>
                    Start & End Time
                </Typography>
                <ArrayInput source="occurrences" label = {false}>
                    <SimpleFormIterator>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={6}>
                                <DateTimeInput source="start_date" label="Start Date" validate={required()} fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DateTimeInput source="end_date" label="End Date" validate={required()} fullWidth />
                            </Grid>
                        </Grid>
                    </SimpleFormIterator>
                </ArrayInput>
            </Box>

            <Box mt={2}>
                <Typography variant="h6" gutterBottom sx={{paddingBottom: 2}}>
                    Images
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ImageInput source="background_image" label="Background Image" accept="image/*" fullWidth>
                            <ImageField source="src" title="title" />
                        </ImageInput>
                    </Grid>
                    <Grid item xs={12}>
                        <ImageInput source="event_image" label="Event Image" accept="image/*" fullWidth>
                            <ImageField source="src" title="title" />
                        </ImageInput>
                    </Grid>
                </Grid>
            </Box>
        </SimpleForm>
    </Box>
    );
};

export const EventCreate = (props) => (
    <Create {...props} redirect="/events">
        <EventForm mode="create" />
    </Create>
);

export const EventEdit = (props) => (
    <Edit {...props} redirect="/events">
        <EventForm mode="edit" />
    </Edit>
);