import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // React Router for redirection
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Box, Button, TextField, Typography, CircularProgress, Alert } from '@mui/material';
import Logo from './logo.svg'; 

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  const handleLogin = async () => {
    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const token = await userCredential.user.getIdTokenResult();
      if (token.claims.admin) {
        console.log('Logged in as admin');
        navigate('/events');
      } else {
        setError('Not authorized as admin');
      }
    } catch (error) {
      console.error('Login Failed', error);
      setError('Login Failed: ' + error.message);
    }
    setLoading(false);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Box width={400} p={4} boxShadow={3} borderRadius={2}>
        <Box display="flex" justifyContent="center" mb={2}>
          <img src={Logo} alt="Sociably Admin" style={{ width: '50%', height: 'auto', padding: '20px' }} />
        </Box>
        <Typography variant="h6" textAlign="center" marginBottom={2}>
          Admin Login
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          variant="outlined"
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
        />
        {error && <Alert severity="error" style={{ marginTop: 20 }}>{error}</Alert>}
        <Box mt={4} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            onClick={handleLogin}
            size='large'
          >
            {loading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;