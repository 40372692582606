import { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
import { BrowserRouter as Router } from 'react-router-dom';
import theme from './resources/theme';
import dataProvider from './resources/dataProvider';
import MyLayout from './layout/layout';
import { EventList } from './resources/events/eventList'; 
import { EventCreate, EventEdit } from './resources/events/eventCreateEdit';
import Login from './Login';
import { auth } from './resources/firebase';
import { onAuthStateChanged, signOut } from "firebase/auth";

const App = () => {
  const [user, setUser] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(true); // State to handle initial loading
  
  useEffect(() => {
    return onAuthStateChanged(auth, (firebaseUser) => {
      if (firebaseUser) {
        firebaseUser.getIdTokenResult().then((tokenResult) => {
          if (tokenResult.claims.admin) {
            setUser(firebaseUser);
            initializeData();
          } else {
            setUser(null);
            console.error("Not authorized as admin");
          }
        },
      );
      } else {
        setUser(null);
      }
      setLoading(false);
    });
  }, []);

  async function initializeData() {
    await dataProvider.getList('events', {});
    setInitialized(true);
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || !initialized) {
    return (
      <Router>
        <Login />
      </Router>
    );
  }

  return (
    <Router>
      <Admin dataProvider={dataProvider} theme={theme} layout={MyLayout}>
        <Resource name="events" list={EventList} create={EventCreate} edit={EventEdit} />
      </Admin>
    </Router>
  );
};

export default App;