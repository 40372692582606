// src/components/locationSelectField.js
import { useEffect, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Select, MenuItem, FormControl, InputLabel, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const LocationSelect = ({ onChange, value }) => {
  const dataProvider = useDataProvider();
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [newLocationName, setNewLocationName] = useState('');
  const [newLatitude, setNewLatitude] = useState('');
  const [newLongitude, setNewLongitude] = useState('');

  useEffect(() => {
    const fetchLocations = async () => {
      setLoading(true);
      try {
        const { data } = await dataProvider.getList('locations', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'name', order: 'ASC' }
        });
        setLocations(data);
      } catch (e) {
        setError('Error fetching locations');
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    
    fetchLocations();
  }, [dataProvider]);

  const handleAddLocation = async () => {
    try {
      const { data } = await dataProvider.create('locations', {
        data: {
          name: newLocationName,
          latitude: newLatitude,
          longitude: newLongitude
        }
      });
  
      const newLocation = {
        ...data,
        id: data.location_id
      };
  
      setLocations(prevLocations => [...prevLocations, newLocation]);
      onChange(data.id);
      setOpen(false);
      setNewLocationName('');
      setNewLatitude('');
      setNewLongitude('');
    } catch (e) {
      setError(e.message || 'Error creating location');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <FormControl fullWidth>
      <InputLabel id="location-label">Location</InputLabel>
      <Select
        labelId="location-label"
        id="location-select"
        value={value}
        label="Location"
        onChange={e => onChange(e.target.value)}
      >
        {locations.map(loc => (
          <MenuItem sx={{ minHeight: '48px' }} key={loc.id} value={loc.id}>{loc.location_name}</MenuItem>
        ))}
        <MenuItem onClick={() => setOpen(true)} sx={{ color: 'blue' }}>
            <ListItemIcon>
                <AddIcon sx={{ color: 'blue' }} />
            </ListItemIcon>
            <ListItemText primary="Add new location" />
        </MenuItem>
      </Select>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
            <Typography variant="h6" style={{ flex: 1, color: 'white' }}>
                Add a New Location
            </Typography>
            <IconButton onClick={() => setOpen(false)} style={{ color: 'white' }}>
                <AddIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Location Name"
            type="text"
            fullWidth
            variant="standard"
            value={newLocationName}
            onChange={e => setNewLocationName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="latitude"
            label="Latitude"
            type="text"
            fullWidth
            variant="standard"
            value={newLatitude}
            onChange={e => setNewLatitude(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="longitude"
            label="Longitude"
            type="text"
            fullWidth
            variant="standard"
            value={newLongitude}
            onChange={e => setNewLongitude(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={handleAddLocation}>Add</Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  );
};

export default LocationSelect;