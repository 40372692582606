import { createTheme } from '@mui/material/styles'; 

let theme = createTheme({
  palette: {
    primary: {
      main: '#539DA7', 
    },
    secondary: {
      main: '#2D3748', 
    },
    background: {
      default: '#DFE6E7',
    },
    text: {
      primary: '#122024',
      secondary: '#666C6D',
    },
  },
  typography: {
    fontFamily: 'DM Sans, sans-serif',
    h6: {
      fontWeight: 600,
      fontSize: '1.25rem', // headlineMedium
    },
    h4: {
      fontWeight: 600,
      fontSize: '1.1rem', // headlineLarge
    },
    
    body1: {
      fontSize: '1rem',  // bodyLarge
    },
    body2: {
      fontSize: '0.875rem',  // bodyMedium
    },
  },
  shape: {
    borderRadius: 8,
  },
});

// Then modify the theme with dependent properties
theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#081627',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: '#539DA7',
          color: 'white',
          alignItems: 'center',
          display: 'flex',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255,255,255,0.15)',
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
        textTransform: 'none',
      },
    },
    MuiTextField: {
      root: {
        '& label.Mui-focused': {
          color: '#122024',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#122024',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#86A6A6',
          },
          '&:hover fieldset': {
            borderColor: '#122024',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#122024',
          },
        },
      },
    },
  },
};

export default theme;