import React from 'react';
import { List, ListItem, ListItemText, Divider, Drawer, Box, ListItemButton } from '@mui/material';
import { Logout, MenuItemLink} from 'react-admin';
import { auth } from '../resources/firebase';
import { signOut } from "firebase/auth";
import CssBaseline from '@mui/material/CssBaseline';
import EventIcon from '@mui/icons-material/Event';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import TimerIcon from '@mui/icons-material/Timer';
import LogoutIcon from '@mui/icons-material/Logout';

const CustomMenu = (props) => {

  const handleLogout = () => {
    signOut(auth).then(() => {
        console.log('Logged out successfully!');
        // Perform any additional cleanup or redirection if necessary
    }).catch((error) => {
        console.error('Logout error:', error);
    });
  };

  const { ...other } = props;

  const categories = [
    {
      id: 'Events',
      children: [
        { id: 'Events List', icon: <EventIcon />, path: '/events' },
        { id: 'Add Event', icon: <AddBoxIcon />, path: '/events/create' },
        { id: 'Database', icon: <DnsRoundedIcon />, path: '/database' },
      ],
    },
    {
      id: 'Admin',
      children: [
        { id: 'Authentication', icon: <PeopleIcon />, path: '/authentication' },
        { id: 'Analytics', icon: <SettingsIcon />, path: '/analytics' },
        { id: 'Performance', icon: <TimerIcon />, path: '/performance' },
      ],
    },
  ];

  return (
    <Drawer
        variant="permanent"
        {...other}
        sx={{
            '& .MuiDrawer-paper': {
                bgcolor: '#DDEBED', 
                color: '122024', 
                width: 240, 
                height: '100vh',
                display: 'flex',
            }
        }}
    >     
        <CssBaseline />
    <List disablePadding>
        {categories.map((category) => (
          <Box key={category.id}>
            <ListItem sx={{ py: 3, px: 3, fontWeight: 800 }}>
              <ListItemText >{category.id}</ListItemText>
            </ListItem>
            {category.children.map((item) => (
              <MenuItemLink
                key={item.id}
                to={item.path}
                primaryText={item.id}
                leftIcon={item.icon}
                dense
              />
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
      <Box sx={{ mt: 'auto', mb: 2, width: '100%' }}>
          <ListItemButton disablePadding onClick={handleLogout}>
              <LogoutIcon sx={{ color: 'red' }}/>
              <ListItemText primary="Logout" sx={{ py: 6, px: 2, color: 'red', fontWeight: 800 }}/>
          </ListItemButton>
      </Box>
    </Drawer>
  );
};

export default CustomMenu;