import React from 'react';
import { Layout, AppBar, UserMenu } from 'react-admin';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomMenu from './menu';
import { Box, Typography } from '@mui/material';
import { IconButton } from '@mui/material';

const SettingsButton = () => (
    <IconButton color="inherit">
        <SettingsIcon />
    </IconButton>
);

export const MyAppBar = (props) => {
    return (
      <AppBar {...props}>
        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center'}}>
          <Typography variant="h6" component="h1" sx={{ flexGrow: 1, color: '#ffff' }}>
            Sociably Admin
          </Typography>
          <Box sx={{ flexShrink: 0 }}>
            <SettingsIcon />
          </Box>
        </Box>
        <UserMenu />
      </AppBar>
    );
  };

export const MyLayout = (props) => (
    <Layout 
        {...props} 
        appBar={MyAppBar} 
        menu={CustomMenu}
        sx={{
            minHeight: '100vh',
            '& .RaLayout-content': {
                marginTop: 0, // Remove margin at the top
                paddingTop: 0, // Ensure padding is also removed if it's there
            },
            '.MuiDrawer-paper': { width: '280px' },
        }}
    >
        <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#fff'}}>
            {props.children}
        </Box>
    </Layout>
);

export default MyLayout;